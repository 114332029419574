const serviceCategories = {
  "Conceitos fundamentais de design (para gerar orçamentos e apresentar pedidos de planejamento)": [
    "Que tipo de serviço precisa?",
    "Extensão",
    "Conversão loft",
    "Renovação",
    "Nova construção",
    "Outros",
  ],
  "Plantas detalhadas em conformidade (para construtores e regulamentos de construção)": [
    "Que tipo de serviço precisa?",
    "Extensão",
    "Conversão loft",
    "Renovação",
    "Nova construção",
    "Outros",
  ],
  "Renovação / instalação de banheiro": [
    "Quantas casas de banho precisam de renovação / instalação de banheiro?",
    "1 Casa de banho",
    "2 ou mais Casas de banhos",
  ],
  "Instalar ou substituir um dispositivo": [
    "É necessário mais algum serviço?",
    "Sim",
    "Não",
  ],
  "Reparar": ["É alguma fuga?", "Sim", "Não"],
  "Azulejos": [
    "O que involve o teu trabalho de Azulejos?",
    "Novo ou Substituição de azulejos",
    "Reparação de azulejos",
    "Outros",
  ],
  "Construindo uma parede": [
    "Que tipo de parede quer construir?",
    "Parede pequena, decorativa",
    "Parede pequena",
    "Parede de jardim",
    "Parede mestra",
    "Mais que uma parede",
  ],
  "Construção de uma estrutura": [
    "Que tipo de estrutura quer construir?",
    "Anexo",
    "Varanda",
    "Garagem",
    "Extensão",
    "Outros",
  ],
  "Trabalho de alvenaria personalizado": [
    "Que tipo de trabalho de alvenaria?",
    "Pilar",
    "Degraus",
    "Outros",
  ],
  "Alterações na parede": [
    "Que tipo de alteração quer fazer?",
    "Instalação / Subtituição de janelas ou portas",
    "Remoção de parede",
    "Outros",
  ],
  "Reapontamento": [
    "Que tipo de reencaminhamento?",
    "Alvenaria",
    "Pedra natural",
  ],
  "Trabalho na chaminé": [
    "O que precisa de fazer na sua chaminé?",
    "Remoção",
    "Reconstruir / Grandes alterações",
    "Reemcaminhamento / Reparação",
    "Outros",
  ],
  "Reparos": [
    "Que tipo de reparação necessita?",
    "Pequena",
    "Média",
    "Grande",
  ],
  "Portas, janelas e pisos": [
    "Precisas de ajuda de um capinteiro em quê?",
    "Portas indoor",
    "Portas outdoor",
    "Janelas",
    "Chão",
    "Rodapés e Arquitraves",
    "Vários dos acima",
  ],
  "Fabricação, montagem e reparos de móveis": [
    "Oque precisas?",
    "Móveis feitos",
    "Móveis por montar (Ikea, etc....)",
    "Reparações a móveis",
  ],
  "Unidades de cozinha e bancadas": [
    "Precisas de ajuda de um capinteiro em quê?",
    "Colocar bancada de madeira",
    "Reparar bancada de madeira",
    "Instalar unidades",
    "Construir unidades de cozinha personalizadas",
    "Reparar / Ajustar unidades de cozinha",
  ],
  "Área coberta": [
    "Que tipo de trabalho precisas?",
    "Construir um deck novo",
    "Reparar um deck existente",
  ],
  "Piso novo ou substituição": [
    "Que tipo de trablho de piso necessita?",
    "Carpet",
    "Linóleo",
    "Laminado",
    "Tijoleira",
    "Projetado",
    "Madeira",
    "Outros",
  ],
  "Lixamento / restauração": [
    "Que tipo de trablho de piso necessita de lixar / restaurar?",
    "Projetado",
    "Madeira",
    "Outros",
  ],
  "Reparo / ajuste": [
    "Que tipo de trablho de piso necessita de reparação?",
    "Carpet",
    "Linóleo",
    "Laminado",
    "Tijoleira",
    "Projetado",
    "Madeira",
    "Outros",
  ],
  "Caldeira": [
    "Têm que tipo de caldeira?",
    "Gás",
    "Elétrica",
    "Oléo",
    "Outra",
  ],
  "Tubulação / fornecimento": ["Que tipo de tubo requer o trabalho?", "Gás", "Água", "Outro"],
  "Radiadores": [
    "O que precisa?",
    "Problema pequeno (fugas, etc..)",
    "Mover ou Substituir",
    "Outro",
  ],
  "Aquecimento de piso": [
    "Que tipo de piso aquecido necessita?",
    "Caldeira fornecida",
    "Eletrico",
  ],
  "Instalação completa do sistema": [
    "Têm que tipo de sistema?",
    "Gás",
    "Elétrico",
    "Oléo",
    "Outro",
  ],
  "Chaminé": [
    "O que precisa de fazer na sua chaminé?",
    "Varrer",
    "Remoção",
    "Reconstruir / Grandes alterações",
    "Reemcaminhamento / Reparação",
    "Outros",
  ],
  "Lareira": [
    "Qual é o seu sistema?",
    "Gás",
    "Elétrico",
    "Combustivél sólido (madeira, etc..)",
  ],
  chimneyFireFlue: [
    "O que precisa de fazer na sua chaminé?",
    "Instalação ou alteração",
    "Reparação",
  ],
  "Conversão de sotão": [
    "Qual tipo de conversão de sótão você deseja?",
    "Conversão de sótão com mudanças estruturais",
    "Conversão de sótão (sem mudanças estruturais)",
    "Conversão de sótão para fins de armazenamento",
    "Instalar uma claraboia",
  ],
  "Conversão de um espaço existente": [
    "Quão extenso é o seu trabalho de conversão?",
    "Conversão de um único cômodo",
    "Conversão de uma garagem / anexo pequeno",
    "Conversão de vários quartos ou anexo grande",
    "Conversão de toda a propriedade",
  ],
  "Alteração de paredes": [
    "E qual das seguintes opções melhor descreve o trabalho de alteração da sua parede?",
    "Instalação ou substituição de janela ou porta",
    "Remoção de parede",
    "Outros",
  ],
  "Restaurar ou melhorar um espaço existente": [
    "De que tipo de trabalho se trata?",
    "Pequena reforma",
    "Grande reforma",
    "Reforma total",
  ],
  "Sim - Preciso apenas de ajuda para resolver o problema": [
    "Qual é a dimensão da área afetada?",
    "Pequena área isolada",
    "Área extensa",
    "Não tenho certeza",
  ],
  "Remoção de lixo apenas": [
    "Quanto lixo você precisa remover?",
    "Pequena quantidade, que cabe em uma carrinha ou carro",
    "Quantidade média, que cabe em uma carrinha média",
    "Grande quantidade, que cabe em uma carrinha grande",
    "Limpeza completa do local ou mais",
  ],
  "Demolição de edifícios / estruturas": [
    "Aproximadamente, qual é o tamanho da estrutura que precisa ser demolida?",
    "Pequena",
    "Média",
    "Grande",
    "Muito grande",
  ],
  "Derrubar uma parede": [
    "Que tipo de parede você deseja remover?",
    "Parede de estrutura de madeira",
    "Parede não portante (que não suporta cargas estruturais).",
    "Parede portante (que suporta cargas estruturais).",
  ],
  "Instalar / Reparar uma entrada": [
    "Qual é o material de superfície que você precisa?",
    "Pavimentação de blocos (também conhecida como 'pavimentação intertravada' ou 'pavimentação de pavers').",
    "Brita (também conhecida como 'cascalho' ou 'pedra britada').",
    "Cimento",
    "Asfalto ou resina.",
    "Outros",
  ],
  "Pavimentação, pátios e caminho": [
    "Colocar ou substituir um pátio.",
    "Colocar ou substituir um caminho/passeio.",
    "Reparar pavimentação, caminho/passeio ou pátio.",
  ],
  "Refazer circuitos": [
    "Quantas instalações elétricas precisam ser refeitas?",
    "Parte da minha propriedade.",
    "Entire property",
  ],
  "Caixas de fusíveis": [
    "De que tipo de trabalho em caixa de fusíveis se trata?",
    "Substituição da caixa de fusíveis - no mesmo local.",
    "Substituição da caixa de fusíveis - no mesmo local diferente.",
    "Instalação de uma nova caixa de fusíveis.",
    "Outros",
  ],
  "Fittings e aparelhos elétricos": [
    "O que você deseja instalar?",
    "Instalações elétricas.",
    "Eletrodomésticos.",
    "Sistemas de segurança.",
    "Caldeiras e sistemas de aquecimento.",
  ],
  "Verificação ou certificado de segurança": [
    "O que você precisa verificar ou certificar?",
    "Um único eletrodoméstico.",
    "Vários eletrodomésticos ou propriedade inteira.",
    "Propriedade comercial.",
  ],
  "Falhas e reparos elétricos": [
    "Qual das opções abaixo melhor descreve o seu problema?",
    "Simples",
    "Complexo",
  ],
  "Extensão de propriedade": [
    "Em qual estágio do projeto você se encontra no momento?",
    "Estou pronto / quase pronto para o início do trabalho.",
    "Preciso de ajuda com o design ou planejamento antes de iniciar o trabalho.",
    "Ainda não estou pronto para construir, ainda estou explorando opções.",
  ],
  "Conversão de loft": [
    "Que tipo de conversão de sótão você deseja?",
    "Conversão de sótão com alterações estruturais.",
    "Conversão de sótão (sem alterações estruturais).",
    "Conversão de sótão para fins de armazenamento.",
    "Instalar uma claraboia",
  ],
  "Uma varanda": [
    "Que tipo de trabalho em varanda?",
    "Apenas uma varanda nova.",
    "Preciso de uma nova varanda e alguns trabalhos adicionais.",
  ],
  "Somente calhas": [
    "Qual trabalho de calhas você precisa?",
    "Instalar / substituir",
    "Reparar (exemplo: vazamento)",
    "Limpeza / bloqueios",
  ],
  "Somente beirais e / ou guarnições": [
    "O que você deseja ter feito com as suas beirais e / ou guarnições",
    "Instalar / substituir",
    "Reparar",
  ],
  "Ambos": [
    "O que você deseja ter feito em suas fáscias, sofitos e sarjetas?",
    "Instalar / substituir",
    "Reparar",
  ],
  "Esgrima": [
    "Que tipo de cerca gostaria?",
    "Cerca de painel",
    "Cerca de borda de pena",
    "Outros",
  ],
  "Portões": [
    "Quantos portões precisa?",
    "1",
    "2",
    "3 ou mais",
    "portão à medida",
  ],
  "Esgrima e portões": [
    "Que tipo de cerca gostaria?",
    "Cerca de painel",
    "Cerca de borda de pena",
    "Outros",
  ],
  "Reparar uma cerca ou portão": ["Pequena reparação", "Grande reparação"],
  "Jardinagem geral": [
    "Qual das opções a seguir melhor descreve seu trabalho de jardinagem?",
    "Pequeno trabalho de jardinagem pontual",
    "Grande trabalho de jardinagem pontual",
    "Pequena manutenção contínua do jardim",
    "Grande manutenção contínua do jardim",
  ],
  "Paisagismo": [
    "Qual das opções a seguir melhor descreve seu trabalho de jardinagem?",
    "Reparações de jardins",
    "Adicionar ou substituir elementos",
    "Projeto de reforma de jardim/paisagismo",
  ],
  "Cirurgia de árvores": [
    "Que tipo de serviço de cirurgia de árvore você precisa?",
    "Cortar ou Cobrir",
    "Cortar",
    "Apenas remoção de tocos",
    "Diagnóstico / Avaliação",
    "Arbustos ou outras tarefas de jardinagem",
    "Outros",
  ],
  "Verificação de segurança de gás": [
    "O que você precisa certificado?",
    "Caldeira",
    "Único aparelho",
    "Vários aparelho",
  ],
  "Serviço de caldeira ou aparelho": [
    "O que você precisa de serviço?",
    "Caldeira",
    "Único aparelho",
    "Vários aparelho",
  ],
  "Instalar ou substituir caldeira ou aparelho": [
    "Que tipo de aparelho você deseja instalar ou substituir?",
    "Aquecedor de água a Gás",
    "Fogão a gás ou forno",
    "Outro ou vários dos anteriores",
  ],
  "Mover ou remover caldeira ou aparelho": ["Deseja remover uma caldeira a gás?", "Sim", "Não"],
  "Apenas tubulações": [
    "O que precisa ser mudado com a tubulação?",
    "Desconecte ou tampe a tubulação",
    "Instalar ou alterar tubulações",
    "Outros",
  ],
  "Problema ou reparo": [
    "Qual eletrodoméstico precisa de reparo?",
    "Caldeira",
    "Fogão a gás ou forno",
    "Lareira a gás",
    "Outros",
  ],
  "Fundações para uma estrutura a ser construída": [
    "Para que você quer fundações?",
    "Casa nova",
    "Extensão da casa",
    "Dependência ou garagem",
    "Cabana",
    "Outros",
  ],
  "Isolamento de parede":[
    "Quantas paredes precisam de isolamento?",
    "1 parede",
    "Várias paredes",
    "Casa inteira",
  ],
  "Isolamento de piso":[
    "Quantos quartos precisam de isolamento?",
    "1 - 2 quartos",
    "3 - 4 quartos",  
    "5+ quartos",
  ],
  "Instalação de cozinha nova": [
    "Qual o tamanho da sua cozinha?",
    "Ampla reforma na cozinha",
    "Reforma de cozinha padrão",
    "Outros",
  ],
  "Instalação de bancada": [
    "Qual material da bancada gostaria?",
    "Pedra natural",
    "Composto",
    "Madeira sólida",
    "Laminada",
    "Outros",
  ],
  "Instalar aparelho (pia, forno, lava-louças, etc.)": [
    "Que tipo de aparelho necessita de instalação?",
    "Fogão a gás / bicombustível / forno",
    "Fogão/forno elétrico",
    "Máquina de lavar",
    "Lava-louças",
    "Afundar",
    "Outros",
  ],
  "Instalar novas fechaduras": [
    "Quantas fechaduras você precisa instalar?",
    "1 Fechadura",
    "2 Fechaduras",
    "3 ou mais Fechaduras",
  ],
  "Reparar fechaduras": [
    "Quantas fechaduras você precisa reparar?",
    "1 Fechadura",
    "2 Fechaduras",
    "3 ou mais Fechaduras",
  ],
  "Conversão de sótão com alterações estruturais": [
    "Para que tipo de anexo é a conversão do loft?",
    "Desanexado",
    "Geminado",
    "Terraço",
    "Bangalô",
    "Outros",
  ],
  "Conversão de sótão (sem alterações estruturais)": [
    "Para que tipo de anexo é a conversão do loft?",
    "Desanexado",
    "Geminado",
    "Terraço",
    "Bangalô",
    "Outros",
  ],
  "Conversão de sótão para fins de armazenamento": [
    "O que você precisa fazer no seu loft?",
    "Embarque no sótão",
    "Embarque mais trabalho adicional",
  ],
  "Eu possuo o terreno em que planejo construir": [
    "Tem permissão de planejamento?",
    "Sim",
    "Em progresso",
    "Não",
  ],
  "Estou comprando o terreno em que planejo construir": [
    "Tem permissão de planejamento?",
    "Sim",
    "Em progresso",
    "Não",
  ],
  "Não possuo o terreno em que planejo construir": [
    "Tem permissão de planejamento?",
    "Sim",
    "Em progresso",
    "Não",
  ],
  "Reboco (interior)": [
    "Que tipo de gesso você precisa?",
    "Desnatar apenas",
    "Placa de gesso e skim",
    "Outro",
  ],
  "Texturização (exterior)": [
    "O que você precisa renderizado?",
    "Exterior da casa",
    "Parede(s) do jardim",
  ],
  "Radiador": [
    "O que precisa ser feito?",
    "Problema menor (por exemplo, vazamento, sangramento, batidas",
    "Mover ou substituir",
    "Outros",
  ],
  "Caldeiras": [
    "Que tipo de caldeira você tem?",
    "Gás",
    "Óleo",
    "Elétrico",
    "Outros",
  ],
  "Aparelhos": [
    "Quantos aparelhos você precisa instalar ou substituir?",
    "1 item",
    "2 to 3 items",
    "4 or more items",
  ],
  "Fixações": [
    "Como um encanador pode ajudá-lo com seus acessórios?",
    "Instalar ou substituir",
    "Reparação",
  ],
  "Tubulações, torneiras e drenagem": [
    "Existe a possibilidade de que seu trabalho envolva tubulação de gás?",
    "Sim",
    "Não",
  ],
  "Entendo que este serviço é para grandes projetos que exigem gerenciamento e supervisão. Se você precisar de vários trabalhos que abranjam várias áreas, publique-os separadamente nas categorias comerciais apropriadas.": [
    "O trabalho envolve mudar o propósito ou a estrutura de uma sala?",
    "Sim",
    "Não",
  ],
  "Telhado novo ou de substituição": [
    "Que tipo de telhado você precisa?",
    "Telhado inclinado",
    "Telhado plano",
  ],
  "Reparo ou avaliação de telhado": [
    "Que tipo de telhado você precisa?",
    "Telhado inclinado",
    "Telhado plano",
  ],
  "Trabalho de chaminé": [
    "Que trabalho de chaminé precisa ser feito?",
    "Instalar ou reconstruir nova chaminé",
    "Remover uma chaminé existente",
    "Consertar ou reencaixar minha chaminé",
    "Tampa de chaminé",
    "Outros",
  ],
  "Sistema de alarme de segurança": [
    "Qual serviço de alarmes precisa?",
    "Instalação",
    "Reparo de serviço",
    "Remoção",
    "Outro",
  ],
  "Câmera de segurança / inteligente": [
    "Que tipo de serviço de câmera de segurança precisa?",
    "Instalação",
    "Serviço de reparo",
    "Remoção",
    "Outros",
  ],
  "Construção": [
    "Que tipo de trabalho de pedra você precisa?",
    "Silhar",
    "Cascalho aleatório",
    "Pedra seca",
    "Outros",
  ],
  "Reparação": [
    "Que tipo de trabalho de pedra você precisa reparar?",
    "Silhar",
    "Cascalho aleatório",
    "Pedra seca",
    "Outros",
  ],
  "Reapontamentos": [
    "Que tipo de trabalho de pedra você precisa reencaminhamento?",
    "Silhar",
    "Cascalho aleatório",
    "Outros",
  ],
  "Telhas novas ou de substituição": [
    "Quantos metros quadrados precisam ser azulejados?",
    "Menos de 2m²",
    "Menos de 2m²",
    "2 - 14m²",
    "15 - 30m²",
    "31 - 50m²",
    "Mais de 50m²",
    "Comercial ou projeto",
    "Outros",
  ],
  "Reparação / rejuntes de azulejos": [
    "Aproximadamente, qual é o tamanho da área afetada?",
    "Pequena (menos de 2m²)",
    "Grande (2m² ou mais)",
  ],
  "Janelas novas": [
    "Qual tipo de janela você precisa?",
    "Madeira",
    "PVC",
    "Alumínio",
    "Outro",
  ],
  "Portas novas (internas ou externas)": [
    "As portas são internas ou externas?",
    "Portas internas",
    "Portas externas",
  ],
  "Substituição de vidro": [
    "Quantos vidros precisam ser substituídos?",
    "1",
    "2 - 3",
    "4 ou mais",
  ],
  "Reparações": [
    "Que tipo de janelas/portas precisam de reparo?",
    "Madeira",
    "PVC and metal",
  ]
};
export default serviceCategories;