export const servicesData = {
  "Serviços de Arquitetura": {
    "Conceitos fundamentais de design (para gerar orçamentos e apresentar pedidos de planejamento)": 8.0,
    "Plantas detalhadas em conformidade (para construtores e regulamentos de construção)": 8.0,
    "Cálculos de estrutura de construção": 8.0,
  },
  "Instalação de Banheiros": {
    "Renovação / instalação de banheiro": 6.0,
    "Instalar ou substituir um dispositivo": 5.0,
    Reparar: 5.0,
    Azulejos: 6.0,
  },
  "Alvenaria & Rejuntamento": {
    "Construindo uma parede": 5.0,
    "Construção de uma estrutura": 5.0,
    "Trabalho de alvenaria personalizado": 5.0,
    "Alterações na parede": 5.0,
    Reapontamento: 5.0,
    "Trabalho na chaminé": 4.0,
    Reparos: 5.0,
  },
  "Carpintaria & Marcenaria": {
    "Portas, janelas e pisos": 6.0,
    "Fabricação, montagem e reparos de móveis": 5.0,
    "Unidades de cozinha e bancadas": 6.0,
    "Área coberta": 5.0,
  },
  "Carpetes, Lino & Pisos": {
    "Piso novo ou substituição": 5.0,
    "Lixamento / restauração": 5.0,
    "Reparo / ajuste": 5.0,
  },
  "Aquecimento Central": {
    Caldeira: 6.0,
    "Tubulação / fornecimento": 6.0,
    Radiadores: 6.0,
    Termostato: 6.0,
    "Aquecimento de piso": 6.0,
    "Instalação completa do sistema": 7.0,
  },
  "Chaminé & Lareira": {
    Chaminé: 5.0,
    Lareira: 5.0,
  },
  Conversões: {
    "Conversão de sotão": 5.0,
    "Conversão de um espaço existente": 6.0,
    "Alteração de paredes": 5.0,
    "Restaurar ou melhorar um espaço existente": 5.0,
  },
  "Prova de Humidade": {
    "Não - Preciso de ajuda para investigar": 6.0,
    "Sim - Preciso apenas de ajuda para resolver o problema": 6.0,
  },
  "Demolição & Limpeza": {
    "Remoção de lixo apenas": 5.0,
    "Demolição de edifícios / estruturas": 8.0,
    "Derrubar uma parede": 5.0,
  },
  "Entradas & Paving": {
    "Instalar / Reparar uma entrada": 5.0,
    "Limpar ou selar uma entrada": 5.0,
    "Kerb suspenso (crossover)": 5.0,
    "Pavimentação, pátios e caminhos": 5.0,
  },
  Elétrica: {
    "Refazer circuitos": 6.0,
    "Caixas de fusíveis": 6.0,
    "Fittings e aparelhos elétricos": 6.0,
    "Verificação ou certificado de segurança": 6.0,
    "Falhas e reparos elétricos": 6.0,
  },
  Ampliações: {
    "Extensão de propriedade": 6.0,
    "Conversão de loft": 6.0,
    "Uma varanda": 6.0,
    "Edifício anexo": 6.0,
    "Alterações internas": 6.0,
  },
  "Fascias, Soffits & Calhas": {
    "Somente calhas": 5.0,
    "Somente beirais e / ou guarnições": 5.0,
    Ambos: 5.0,
  },
  Cercas: {
    Esgrima: 5.0,
    Portões: 5.0,
    "Esgrima e portões": 5.0,
    "Reparar uma cerca ou portão": 4.0,
  },
  "Jardinagem & Paisagismo": {
    "Jardinagem geral": 6.0,
    Paisagismo: 5.0,
    "Cirurgia de árvores": 6.0,
  },
  Gás: {
    "Verificação de segurança de gás": 6.0,
    "Serviço de caldeira ou aparelho": 5.0,
    "Instalar ou substituir caldeira ou aparelho": 5.0,
    "Mover ou remover caldeira ou aparelho": 5.0,
    "Apenas tubulações": 5.0,
    "Problema ou reparo": 5.0,
  },
  "Terraplenagem & Fundações": {
    "Fundações para uma estrutura a ser construída": 6.0,
    "Drenagem e tubulação": 6.0,
    "Terraplanagem geral do jardim": 6.0,
  },
  "Faz-tudo": {
    "O trabalho inclui trabalho elétrico": 5.0,
    "O trabalho não inclui trabalho elétrico": 5.0,
  },
  Isolamento: {
    "Isolamento de sótão / telhado": 5.0,
    "Isolamento de parede": 5.0,
    "Isolamento de piso": 5.0,
  },
  "Instalação de Cozinhas": {
    "Instalação de cozinha nova": 6.0,
    "Instalação de bancada": 6.0,
    "Reforma / substituição de portas de armário": 5.0,
    "Instalar aparelho (pia, forno, lava-louças, etc.)": 5.0,
    "Reparo menor": 5.0,
    "Vários dos itens acima ou outro": 5.0,
  },
  Chaveiro: {
    "Instalar novas fechaduras": 4.0,
    "Reparar fechaduras": 4.0,
    "Outro (ex. trancado do lado de fora)": 5.0,
  },
  "Conversão de Sótão": {
    "Conversão de sótão com alterações estruturais": 6.0,
    "Conversão de sótão (sem alterações estruturais)": 6.0,
    "Conversão de sótão para fins de armazenamento": 6.0,
    "Instalar uma claraboia": 6.0,
  },
  "Nova Construção": {
    "Eu possuo o terreno em que planejo construir": 6.0,
    "Estou comprando o terreno em que planejo construir": 6.0,
    "Não possuo o terreno em que planejo construir": 6.0,
  },
  "Pintura & Decoração": {
    "Pintura interna": 5.0,
    "Pintura externa": 5.0,
    Ambos: 5.0,
  },
  "Gesso & Revestimento": {
    "Reboco (interior)": 5.0,
    "Texturização (exterior)": 5.0,
  },
  Encanamento: {
    Radiador: 5.0,
    Caldeiras: 5.0,
    Aparelhos: 5.0,
    Fixações: 5.0,
    "Tubulações, torneiras e drenagem": 5.0,
  },
  Telhados: {
    "Telhado novo ou de substituição": 5.0,
    "Reparo ou avaliação de telhado": 5.0,
    "Trabalho de chaminé": 5.0,
    "Algo mais": 5.0,
  },
  "Sistemas de Segurança": {
    "Sistema de alarme de segurança": 5.0,
    "Câmera de segurança / inteligente": 5.0,
    "Sistema de entrada": 5.0,
    "Alarmes de fumaça": 5.0,
    "Luzes de segurança": 5.0,
    Trancas: 5.0,
  },
  Pedreiro: {
    Construção: 5.0,
    Reparação: 5.0,
    Reapontamentos: 5.0,
  },
  Azulejista: {
    "Telhas novas ou de substituição": 5.0,
    "Reparação / rejuntes de azulejos": 5.0,
  },
  "Cirurgia de Árvores": {
    "Poda ou corte": 5.0,
    "Corte (abate)": 5.0,
    "Remoção apenas de toco": 5.0,
    "Diagnóstico / Avaliação": 4.0,
    "Arbustos ou outras tarefas de jardinagem": 5.0,
  },
  "Instalação de Janelas & Portas": {
    "Janelas novas": 5.0,
    "Portas novas (internas ou externas)": 5.0,
    "Janelas e portas externas novas": 5.0,
    "Substituição de vidro": 5.0,
    Reparações: 5.0,
  },
};
