const serviceCategories = {
  "1 Casa de banho": [
    "Que tipo de banheiro?",
    "Banheiro principal",
    "Suíte",
    "Toalete/lavabo",
    "Outros",
  ],
  
};
export default serviceCategories;
